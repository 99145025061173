@import '../../node_modules/normalize.scss/normalize.scss';
@import './variables';
@import './mixins';

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-size: 100%;
    font-family: $main-font;
}

a {
    &:hover, &:focus {
        text-decoration: none;
    }
}

// Component Animations
.transition-enter {
    opacity: 0.01;
}

.transition-enter.transition-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.transition-leave {
    opacity: 1;
}

.transition-leave.transition-leave-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
}

.transition-appear {
    opacity: 0.01;
}

.transition-appear.transition-appear-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}