// colors
$teal: #7ec4c1;
$teal-light: #b3dcd9;
$teal-dark: #428f8b;
$teal-darker: #3b817e;
$green: #B0C97F;
$green-light: #C9E59A;
$green-dark: #92AF38;
$beansprout: #eaf5d6;
$navy: #00384c;
$navy-light: #446D7D;
$navy-lighter: #439CBF;
$red: #db2126;
$pink: #f6e4e4;
$orange: #f38c20;
$orange-light: #F9A94D;
$orange-dark: #c87129;
$pith: #f9f0e6;
$gray-3: #303030;
$gray: #595555;
$gray-6: #626262;
$gray-8: #848280;
$gray-9: #9f9f9f;
$gray-a: #a9a9a9;
$gray-c: #c9c9c9;
$gray-d: #d3d3d3;
$off-white: #efefef;

// typography
$main-font: 'Open Sans', sans-serif;

$font-size-base: 1em;
$font-size-uge: 2.5em;
$font-size-md: 1.14em;
$font-size-sm: 0.875em;
$font-size-xs: 0.75em;

$fw-xbold: 700;
$fw-bold: 600;
$fw-normal: 400;

$line-height: 1.5;
$line-height-title: 1.2;
$container: 1130px;
$form-item-margin: 1.3em;
$form-input-side-padding: 0.5em;
$form-xl-value-space: 0.438em;

// breakpoints
$break-sm: 540px;
$break-xs: 340px;

// other
$trans-short: 200ms;
$icon-easing: ease-in-out;
$disabled-opacity: 0.8;

// containers
$container: 1130px;
$container-sm: 700px;