// App.js can't access the node_modules folder since it lives outside of src
// but scss files can. So we import the global styles from here.
@import 'Common/styles/styles';
@import '../../node_modules/smart-focus-ring/lib/styles.css';

// * fonts from https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=latin

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/open-sans-v18-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-regular.svg#OpenSans')
            format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/open-sans-v18-latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-600.svg#OpenSans')
            format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-700.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/open-sans-v18-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-700.svg#OpenSans')
            format('svg'); /* Legacy iOS */
}
body {
    color: $gray;
    font-size: 14px;
}

a {
    color: $teal-dark;
    text-decoration: none;
    font-weight: $fw-xbold;
    &:hover,
    &:focus {
        color: $teal;
        text-decoration: underline;
    }
}

h1 {
    font-size: 2.86em;
}

h2 {
    font-size: 2.5em;
}

#root {
    height: 100vh;
}
